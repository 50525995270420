<template>
  <div class="customize-main">
    <div class="page-box">
      <div class="page-left">
        <div class="page-title">外包单位档案管理表单页</div>
        <div class="back" @click="goback">返回上一页</div>
      </div>

      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >

        <el-breadcrumb-item>外包单位档案管理表单页</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="from-box">
      <div class="line">Supplier Basic Information 岗位外包单位基本信息</div>
      <div class="line-box">
        <div class="line-title">岗位外包单位名称</div>
        <el-input v-model="SupplierName" placeholder="岗位外包单位名称" />
      </div>
      <div class="line-box">
        <div class="line-title">单位名称缩写</div>
        <el-input v-model="AbbreviationName" placeholder="单位名称缩写" />
      </div>
      <div class="line-box">
        <div class="line-title">营业执照号</div>
        <el-input v-model="CRNO" placeholder="营业执照号" />
      </div>
      <div class="line-box">
        <div class="line-title">营业执照有效期</div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="CRValidDate"
          type="date"
          placeholder="营业执照有效期"
        >
        </el-date-picker>
      </div>
      <div class="line-box">
        <div class="line-title">增值税号</div>
        <el-input v-model="VATNO" placeholder="增值税号" />
      </div>
      <div class="line-box">
        <div class="line-title">公司注册地址</div>
        <el-input v-model="POBoxAndOfficeAddress" placeholder="公司注册地址" />
      </div>
      <div class="line-box line-box3">
        <div class="line-title">外派人员主要类别</div>
        <el-input v-model="FATAbility" placeholder="外派人员主要类别" />
      </div>
      <div class="line-box line-box3">
        <div class="line-title">自有员工数量</div>
        <el-input v-model="FATAbility" placeholder="自有员工数量 " />
      </div>
      <div class="line">Beneficiary Bank Information 收款账户信息</div>
      <div class="line-box">
        <div class="line-title">收款银行名称</div>
        <el-input v-model="BankName" placeholder="收款银行名称" />
      </div>
      <div class="line-box">
        <div class="line-title">账户名称</div>
        <el-input v-model="AccountName" placeholder="账户名称" />
      </div>
      <div class="line-box">
        <div class="line-title">IBAN NO.</div>
        <el-input v-model="IBANNO" placeholder="IBAN NO." />
      </div>
      <div class="line-box line-box3">
        <div class="line-title">银行代码</div>
        <el-input v-model="BankCode" placeholder="银行代码" />
      </div>
      <div class="line-box line-box3">
        <div class="line-title">银行注册地址</div>
        <el-input v-model="BankAddress" placeholder="银行注册地址 " />
      </div>
      <div class="line">Service Agreement Information 服务合同信息</div>
      <div class="line-box">
        <div class="line-title">合同编号</div>
        <el-input v-model="ServiceAgreementSN" placeholder="合同编号" />
      </div>
      <div class="line-box">
        <div class="line-title">首次注册时间</div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="RegistrationDate"
          type="date"
          placeholder="首次注册时间"
        >
        </el-date-picker>
      </div>
      <div class="line-box">
        <div class="line-title">合同有效期</div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="ContractValidity"
          type="date"
          placeholder="合同有效期"
        >
        </el-date-picker>
      </div>
      <div class="line-box">
        <div class="line-title">合同执行状态</div>
        <el-select
          v-model="AgreementStatus"
          placeholder="合同执行状态"
          clearable
        >
          <el-option
            v-for="item in options1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">MDM系统编号</div>
        <el-input v-model="MDMSystemSN" placeholder="MDM系统编号" />
      </div>
      <div class="line-box">
        <div class="line-title">ICMS系统上线编号</div>
        <el-input v-model="ICMSSystemSN" placeholder="ICMS系统上线编号 " />
      </div>
      <div class="line-box line-box3">
        <div class="line-title">合同额</div>
        <el-input v-model="AgreementValue" placeholder="合同额" />
      </div>
      <div class="line-box line-box3">
        <div class="line-title">甲方</div>
        <el-select v-model="FirstParty" placeholder="甲方" filterable clearable>
          <el-option
            v-for="item in GovernmentList"
            :key="item.ID"
            :label="item.NameinChinese"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line">Other Information 其他信息</div>
      <div class="line-box">
        <div class="line-title">联络人姓名</div>
        <el-input v-model="CoordinatorName" placeholder="联络人姓名" />
      </div>
      <div class="line-box">
        <div class="line-title">联络人联系电话</div>
        <el-input v-model="ContactNumber" placeholder="联络人联系电话" />
      </div>
      <div class="line-box">
        <div class="line-title">联络人邮箱</div>
        <el-input v-model="EmailAddress" placeholder="联络人邮箱 " />
      </div>
      <div class="line-box line-box3">
        <div class="line-title">FAT Ability Besides East Province</div>
        <el-input
          v-model="Recommendby"
          placeholder="FAT Ability 
Besides East Province "
        />
      </div>

      <div class="line-box line-box3">
        <div class="line-title">备注</div>
        <el-input v-model="Remark" placeholder="备注 " />
      </div>
      <div class="line-box line-upload">
        <div class="line-title">附件上传1</div>
        <div class="upload-load">
          <div class="left">
            <div class="name" v-if="UploadingDocument1">
              <div class="name-title" @click="download(UploadingDocument1)">
                {{ UploadingDocument1 | stringFormater }}
              </div>
              <span v-if="UploadingDocument1"
                ><i class="el-icon-delete" @click="deleteItem(1)"></i
              ></span>
            </div>
          </div>
          <el-upload
            action="#"
            class="card-upload"
            :auto-upload="true"
            :http-request="uploadAttachment1"
            ref="uploadRef"
          >
            <button class="btn-up disabled">
              <svg-icon class="icon" icon-class="file"></svg-icon>选择 ...
            </button>
          </el-upload>
        </div>
      </div>
      <div class="line-box line-upload">
        <div class="line-title">附件上传2</div>
        <div class="upload-load">
          <div class="left">
            <div class="name" v-if="UploadingDocument2">
              <div class="name-title" @click="download(UploadingDocument2)">
                {{ UploadingDocument2 | stringFormater }}
              </div>
              <span v-if="UploadingDocument2"
                ><i class="el-icon-delete" @click="deleteItem(2)"></i
              ></span>
            </div>
          </div>
          <el-upload
            action="#"
            class="card-upload"
            :auto-upload="true"
            :http-request="uploadAttachment2"
            ref="uploadRef"
          >
            <button class="btn-up disabled">
              <svg-icon class="icon" icon-class="file"></svg-icon>选择 ...
            </button>
          </el-upload>
        </div>
      </div>
      <div class="line-box line-upload">
        <div class="line-title">附件上传3</div>
        <div class="upload-load">
          <div class="left">
            <div class="name" v-if="UploadingDocument3">
              <div class="name-title" @click="download(UploadingDocument3)">
                {{ UploadingDocument3 | stringFormater }}
              </div>
              <span v-if="UploadingDocument3"
                ><i class="el-icon-delete" @click="deleteItem(3)"></i
              ></span>
            </div>
          </div>
          <el-upload
            action="#"
            class="card-upload"
            :auto-upload="true"
            :http-request="uploadAttachment3"
            ref="uploadRef"
          >
            <button class="btn-up disabled">
              <svg-icon class="icon" icon-class="file"></svg-icon>选择 ...
            </button>
          </el-upload>
        </div>
      </div>
      <div class="buttons">
        <el-button class="pop-close" @click="goback">取消</el-button
        ><el-button class="pop-save" @click="editManpowerSupplierListItem"
          >保存</el-button
        >
      </div>
    </div>

    <div class="tabs-box">
      <el-tabs v-model="active" @tab-click="handleClick">
        <el-tab-pane label="当地岗位服务外包单位考核管理" name="1">
          <div class="card-box">
            <div class="screen-box">
              <button class="btn-add btn-pre" @click="newBuilt(1)">
                <i class="fa ion-plus-round"></i>新建
              </button>
              <button class="btn-pre" @click="dialogVisible3 = true">
                <i class="fa fa-upload"></i>导入
              </button>
            </div>
            <div class="table-box">
              <el-table
                :data="tableData"
                height="500"
                border
                tooltip-effect="light"
                :empty-text="loading ? '加载中...' : '暂无数据'"
                :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
              >
                <el-table-column label="#" width="55">
                  <template slot-scope="scope">
                    <el-checkbox></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column
                  type="index"
                  label="序号"
                  width="50"
                ></el-table-column>
                <el-table-column
                  prop="AbbreviationName"
                  label="单位名称缩写"
                ></el-table-column>
                <el-table-column prop="FirstPartyName" label="甲方" width="150">
                </el-table-column>

                <el-table-column prop="RegistrationDate" label="首次注册时间">
                  <template slot-scope="scope">
                    {{ scope.row.RegistrationDate | tableDateFrom }}
                  </template>
                </el-table-column>

                <el-table-column prop="AssessmentDate" label="考核日期">
                  <template slot-scope="scope">
                    {{ scope.row.AssessmentDate | tableDateFrom }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="AssessmentResult"
                  label="考核结果"
                  width="100"
                >
                </el-table-column>
                <el-table-column prop="ServiceAgreementSN" label="合同编号">
                </el-table-column>
                <el-table-column
                  prop="ContractRenewalTimes"
                  label="合同续签次数序号"
                >
                </el-table-column>
                <el-table-column
                  prop="ValidDateofRenwalAagreement"
                  label="新合同有效期"
                >
                  <template slot-scope="scope">
                    {{ scope.row.ValidDateofRenwalAagreement | tableDateFrom }}
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="150">
                  <template slot-scope="scope">
                    <i
                      class="fa fa-edit"
                      @click="getManpowerSupplierAssessmentsItem(scope.row)"
                    ></i>
                    <i
                      class="fa fa-trash"
                      @click="deleteManpowerSupplierAssessmentsItem(scope.row)"
                    ></i>
                  </template>
                </el-table-column>
              </el-table>

              <el-pagination
                @size-change="getManpowerSupplierAssessments(1)"
                @current-change="getManpowerSupplierAssessments"
                :current-page.sync="page"
                :page-size.sync="size"
                :page-sizes="[10, 20, 50, 100]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="奖惩管理" name="2">
          <div class="card-box">
            <div class="screen-box">
              <button class="btn-add btn-pre" @click="newBuilt(2)">
                <i class="fa ion-plus-round"></i>新建
              </button>
              <button class="btn-pre" @click="dialogVisible4 = true">
                <i class="fa fa-upload"></i>导入
              </button>
            </div>
            <div class="table-box">
              <el-table
                :data="tableData"
                height="500"
                border
                tooltip-effect="light"
                :empty-text="loading ? '加载中...' : '暂无数据'"
                :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
              >
                <el-table-column label="#" width="55">
                  <template slot-scope="scope">
                    <el-checkbox></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column
                  type="index"
                  label="序号"
                  width="50"
                ></el-table-column>
                <el-table-column
                  prop="AbbreviationName"
                  label="单位名称缩写"
                ></el-table-column>
                <el-table-column prop="FirstPartyName" label="甲方" width="150">
                </el-table-column>

                <el-table-column prop="RegistrationDate" label="首次注册时间">
                  <template slot-scope="scope">
                    {{ scope.row.RegistrationDate | tableDateFrom }}
                  </template>
                </el-table-column>

                <el-table-column prop="ConversationDate" label="约谈时间">
                  <template slot-scope="scope">
                    {{ scope.row.ConversationDate | tableDateFrom }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="ReasonforConversation"
                  label="约谈原因"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="FinalDecisionAndProcessResult"
                  label="结果记录"
                >
                </el-table-column>

                <el-table-column label="操作" width="150">
                  <template slot-scope="scope">
                    <i
                      class="fa fa-edit"
                      @click="
                        getManpowerSupplierPunishmentAndRewardsItem(scope.row)
                      "
                    ></i>
                    <i
                      class="fa fa-trash"
                      @click="
                        deleteManpowerSupplierPunishmentAndRewardItem(scope.row)
                      "
                    ></i>
                  </template>
                </el-table-column>
              </el-table>

              <el-pagination
                @size-change="getManpowerSupplierPunishmentAndRewards"
                @current-change="getManpowerSupplierPunishmentAndRewards"
                :current-page.sync="page"
                :page-size.sync="size"
                :page-sizes="[10, 20, 50, 100]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog
      title="考核及合同续签管理"
      :visible.sync="dialogVisible1"
      width="99vw"
      :before-close="handleClose1"
      top="1vh"
      close="dialog"
    >
      <div class="dialog-main">
        <OufmTable1
          ref="oufmTable1"
          @update="getManpowerSupplierAssessments"
          @close="handleClose1"
        ></OufmTable1>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose1">关闭</el-button>
        <el-button class="pop-save" @click="save(1)">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="奖惩管理"
      :visible.sync="dialogVisible2"
      width="99vw"
      :before-close="handleClose2"
      top="1vh"
      close="dialog"
    >
      <div class="dialog-main">
        <OufmTable2
          ref="oufmTable2"
          @update="getManpowerSupplierPunishmentAndRewards"
          @close="handleClose2"
        ></OufmTable2>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose2">关闭</el-button>
        <el-button class="pop-save" @click="save(2)">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="导入当地岗位服务外包单位考核管理"
      :visible.sync="dialogVisible3"
      width="600px"
      :before-close="handleClose3"
      top="50px"
      class="dialog"
    >
      <div class="text-box">
        <div class="text-title">模板文件</div>
        <div class="text-name" @click="getDownloadUrl(downloadUrl1)">
          当地岗位服务外包单位考核管理批量导入模板
        </div>
      </div>
      <div class="upload-box2">
        <div class="left">
          <div v-if="name3" class="name">
            <svg-icon class="icon" icon-class="glyphicon-file"></svg-icon>
            {{ name3 }}
          </div>
        </div>
        <el-upload
          action="#"
          class="card-upload"
          :auto-upload="true"
          :http-request="uploadAttachmentAssessment"
          ref="uploadRef"
        >
          <button class="btn-up">
            <svg-icon class="icon" icon-class="file"></svg-icon>选择 ...
          </button>
        </el-upload>
      </div>
      <div class="dialog-footer2">
        <el-button class="pop-close" @click="handleClose3">关闭</el-button>
        <el-button
          class="pop-save pop-right"
          @click="importManpowerSupplierAssessment"
          >导入</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="奖惩管理"
      :visible.sync="dialogVisible4"
      width="600px"
      :before-close="handleClose4"
      top="50px"
      class="dialog"
    >
      <div class="text-box">
        <div class="text-title">模板文件</div>
        <div class="text-name" @click="getDownloadUrl(downloadUrl2)">
          奖惩管理批量导入模板
        </div>
      </div>
      <div class="upload-box2">
        <div class="left">
          <div v-if="name4" class="name">
            <svg-icon class="icon" icon-class="glyphicon-file"></svg-icon>
            {{ name4 }}
          </div>
        </div>
        <el-upload
          action="#"
          class="card-upload"
          :auto-upload="true"
          :http-request="uploadAttachmentSupplier"
          ref="uploadRef"
        >
          <button class="btn-up">
            <svg-icon class="icon" icon-class="file"></svg-icon>选择 ...
          </button>
        </el-upload>
      </div>
      <div class="dialog-footer2">
        <el-button class="pop-close" @click="handleClose4">关闭</el-button>
        <el-button
          class="pop-save pop-right"
          @click="importmanpowerSupplierPunishmentAndReward"
          >导入</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import OufmTable1 from "@/components/tables/oufmTable1.vue";
import OufmTable2 from "@/components/tables/oufmTable2.vue";
import { uploadAttachment } from "@/api/user";
import {
  getGovernmentSystemDicAll,
  getPurchaseOrderApplicationSuppliers,
  getManpowerSupplierListItem,
  editManpowerSupplierListItem,
  getManpowerSupplierAssessments,
  getManpowerSupplierAssessmentsItem,
  deleteManpowerSupplierAssessmentsItem,
  getManpowerSupplierPunishmentAndRewards,
  getManpowerSupplierPunishmentAndRewardsItem,
  deleteManpowerSupplierPunishmentAndRewardItem,
  importManpowerSupplierAssessment,
  getManpowerSupplierAssessmentTemplate,
  importmanpowerSupplierPunishmentAndReward,
  getManpowerSupplierPunishmentAndRewardTemplate,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : "";
    },
    nationalityFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CountryNameCHS : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    stringFormater(data) {
      if (!data) {
        return;
      }
      let name1 = process.env.VUE_APP_SERVER + "upload/";
      let index1 = data.indexOf(name1);
      data = data.slice(index1 + name1.length, data.length - 1);
      const index2 = data.indexOf("/");
      const index3 = data.lastIndexOf(".");
      data = data.slice(index2 + 1, index3);
      return data;
    },

    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
  },
  components: { OufmTable1, OufmTable2 },
  data() {
    return {
      ID: null,
      KeyID: null,
      ServiceAgreementSN: null,
      FirstParty: null,
      FirstPartyName: null,
      RegistrationDate: null,
      ContractValidity: null,
      AgreementStatus: null,
      MDMSystemSN: null,
      ICMSSystemSN: null,
      AgreementValue: null,
      SupplierName: null,
      AbbreviationName: null,
      CRNO: null,
      CRValidDate: null,
      VATNO: null,
      POBoxAndOfficeAddress: null,
      FATAbility: null,
      BankName: null,
      AccountName: null,
      IBANNO: null,
      BankCode: null,
      BankAddress: null,
      CoordinatorName: null,
      ContactNumber: null,
      EmailAddress: null,
      Recommendby: null,
      Remark: null,
      UploadingDocument1: null,
      UploadingDocument2: null,
      UploadingDocument3: null,
      GovernmentList: [],
      purchaseOrderApplicationList: [],
      options1: [
        {
          value: 1,
          label: "正常履行",
        },
        {
          value: 2,
          label: "已关闭",
        },
        {
          value: 3,
          label: "列入黑名单",
        },
      ],
      active: "1",
      page: 1,
      size: 10,
      total: null,
      loading: false,
      tableData: [],
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: null,
      dialogVisible4: null,
      orderByField: null,
      ascOrDesc: null,

      downloadUrl1: null,
      downloadUrl2: null,
      name3: null,
      name4: null,
      uploadUrl1: null,
      uploadUrl2: null,
    };
  },

  methods: {
    goback() {
      this.$router.push("/module/oufm");
    },
    //切换tab触发
    handleClick() {
      this.page = 1;
      switch (this.active) {
        case "1":
          this.getManpowerSupplierAssessments();
          break;
        case "2":
          this.getManpowerSupplierPunishmentAndRewards();
          break;
      }
    },
    deleteItem(index) {
      switch (index) {
        case 1:
          this.UploadingDocument1 = null;
          break;
        case 2:
          this.UploadingDocument2 = null;
          break;
        case 3:
          this.UploadingDocument3 = null;
          break;
      }
    },
    download(data) {
      location.href = data;
    },
    uploadAttachment1(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument1 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument2 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment3(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument3 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    handleClose1() {
      this.dialogVisible1 = false;
      this.$refs.oufmTable1.clearData();
    },
    handleClose2() {
      this.dialogVisible2 = false;
      this.$refs.oufmTable2.clearData();
    },
    handleClose3() {
      this.dialogVisible3 = false;
      this.name3 = null;
    },
    handleClose4() {
      this.dialogVisible4 = false;
      this.name4 = null;
    },
    newBuilt(type) {
      switch (type) {
        case 1:
          this.dialogVisible1 = true;
          setTimeout(() => {
            this.$refs.oufmTable1.getNew(
              this.KeyID,
              this.AbbreviationName,
              this.FirstParty,
              this.RegistrationDate
            );
          }, 100);
          break;
        case 2:
          this.dialogVisible2 = true;
          setTimeout(() => {
            this.$refs.oufmTable2.getNew(
              this.KeyID,
              this.AbbreviationName,
              this.FirstParty,
              this.RegistrationDate
            );
          }, 100);
          break;
      }
    },
    save(type) {
      switch (type) {
        case 1:
          this.$refs.oufmTable1.addCostAllocation();
          break;
        case 2:
          this.$refs.oufmTable2.addCostAllocation();
          break;
      }
    },
    //获取档案管理详情
    getManpowerSupplierListItem(key) {
      getManpowerSupplierListItem({ key }).then((res) => {
        if (res.status === 200) {
          const data = res.response;
          this.ID = data.ID;
          this.ServiceAgreementSN = data.ServiceAgreementSN;
          this.FirstParty = data.FirstParty;
          this.FirstPartyName = data.FirstPartyName;
          this.RegistrationDate = data.RegistrationDate;
          this.ContractValidity = data.ContractValidity;
          this.AgreementStatus = data.AgreementStatus;
          this.MDMSystemSN = data.MDMSystemSN;
          this.ICMSSystemSN = data.ICMSSystemSN;
          this.AgreementValue = data.AgreementValue;
          this.SupplierName = data.SupplierName;
          this.AbbreviationName = data.AbbreviationName;
          this.CRNO = data.CRNO;
          this.CRValidDate = data.CRValidDate;
          this.VATNO = data.VATNO;
          this.POBoxAndOfficeAddress = data.POBoxAndOfficeAddress;
          this.FATAbility = data.FATAbility;
          this.BankName = data.BankName;
          this.AccountName = data.AccountName;
          this.IBANNO = data.IBANNO;
          this.BankCode = data.BankCode;
          this.BankAddress = data.BankAddress;
          this.CoordinatorName = data.CoordinatorName;
          this.ContactNumber = data.ContactNumber;
          this.EmailAddress = data.EmailAddress;
          this.Recommendby = data.Recommendby;
          this.Remark = data.Remark;
          this.UploadingDocument1 = data.UploadingDocument1;
          this.UploadingDocument2 = data.UploadingDocument2;
          this.UploadingDocument3 = data.UploadingDocument3;
        }
      });
    },
    //编辑保存
    editManpowerSupplierListItem() {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ServiceAgreementSN: this.ServiceAgreementSN,
        FirstParty: this.FirstParty,
        FirstPartyName: this.FirstPartyName,
        RegistrationDate: this.RegistrationDate,
        ContractValidity: this.ContractValidity,
        AgreementStatus: this.AgreementStatus,
        MDMSystemSN: this.MDMSystemSN,
        ICMSSystemSN: this.ICMSSystemSN,
        AgreementValue: this.AgreementValue,
        SupplierName: this.SupplierName,
        AbbreviationName: this.AbbreviationName,
        CRNO: this.CRNO,
        CRValidDate: this.CRValidDate,
        VATNO: this.VATNO,
        POBoxAndOfficeAddress: this.POBoxAndOfficeAddress,
        FATAbility: this.FATAbility,
        BankName: this.BankName,
        AccountName: this.AccountName,
        IBANNO: this.IBANNO,
        BankCode: this.BankCode,
        BankAddress: this.BankAddress,
        CoordinatorName: this.CoordinatorName,
        ContactNumber: this.ContactNumber,
        EmailAddress: this.EmailAddress,
        Recommendby: this.Recommendby,
        Remark: this.Remark,
        UploadingDocument1: this.UploadingDocument1,
        UploadingDocument2: this.UploadingDocument2,
        UploadingDocument3: this.UploadingDocument3,
      };
      editManpowerSupplierListItem(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("保存成功");
        }
      });
    },
    //获取考核列表
    getManpowerSupplierAssessments() {
      this.loading = true;
      const data = {
        page: this.page,
        size: this.size,
        supplierKeyID: this.KeyID,
        orderByField: this.orderByField,
        ascOrDesc: this.ascOrDesc,
      };
      getManpowerSupplierAssessments(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },

    //获取考核管理详情，并弹出弹框
    getManpowerSupplierAssessmentsItem(data) {
      this.dialogVisible1 = true;
      getManpowerSupplierAssessmentsItem({ key: data.KeyID }).then((res) => {
        if (res.status === 200) {
          this.$refs.oufmTable1.getEditData(res.response);
        }
      });
    },
    //删除考核管理
    deleteManpowerSupplierAssessmentsItem(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteManpowerSupplierAssessmentsItem({ key: data.KeyID }).then(
          (res) => {
            if (res.status === 200) {
              this.$message.success("删除成功");
              this.getManpowerSupplierAssessments();
            }
          }
        );
      });
    },

    //获取奖惩管理列表
    getManpowerSupplierPunishmentAndRewards() {
      this.loading = true;
      const data = {
        page: this.page,
        size: this.size,
        supplierKeyID: this.KeyID,
        orderByField: this.orderByField,
        ascOrDesc: this.ascOrDesc,
      };
      getManpowerSupplierPunishmentAndRewards(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },

    //获取奖惩管理详情，并弹出弹框
    getManpowerSupplierPunishmentAndRewardsItem(data) {
      this.dialogVisible2 = true;
      getManpowerSupplierPunishmentAndRewardsItem({ key: data.KeyID }).then(
        (res) => {
          if (res.status === 200) {
            this.$refs.oufmTable2.getEditData(res.response);
          }
        }
      );
    },

    //删除奖惩管理

    deleteManpowerSupplierPunishmentAndRewardItem(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteManpowerSupplierPunishmentAndRewardItem({ key: data.KeyID }).then(
          (res) => {
            if (res.status === 200) {
              this.$message.success("删除成功");
              this.getManpowerSupplierPunishmentAndRewards();
            }
          }
        );
      });
    },

    uploadAttachmentAssessment(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix1 = img === "xls";
      const suffix2 = img === "xlsx";
      if (!suffix1 && !suffix2) {
        this.$message.error("只能上传后缀为xls、xlsx的文件!");
        return;
      }

      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.name3 = data.name;
          this.uploadUrl1 = res.response;
          console.log("this.name2", this.name2);
        }
      });
    },

    uploadAttachmentSupplier(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix1 = img === "xls";
      const suffix2 = img === "xlsx";
      if (!suffix1 && !suffix2) {
        this.$message.error("只能上传后缀为xls、xlsx的文件!");
        return;
      }

      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.name4 = data.name;
          this.uploadUrl2 = res.response;
          console.log("this.name2", this.name2);
        }
      });
    },
    //导入
    importManpowerSupplierAssessment() {
      const data = {
        Path: this.uploadUrl1,
        KeyID: this.KeyID,
      };
      importManpowerSupplierAssessment(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("导入成功");
          this.handleClose3();
          this.getManpowerSupplierAssessments();
        }
      });
    },
    //导入
    importmanpowerSupplierPunishmentAndReward() {
      const data = {
        Path: this.uploadUrl2,
        KeyID: this.KeyID,
      };
      importmanpowerSupplierPunishmentAndReward(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("导入成功");
          this.handleClose4();
          this.getManpowerSupplierPunishmentAndRewards();
        }
      });
    },
    //下载附件
    getDownloadUrl(url) {
      location.href = url;
    },
  },
  created() {
    getGovernmentSystemDicAll({ typeID: 1 }).then((res) => {
      if (res.status === 200) {
        this.GovernmentList = res.response;
      }
    });
    getPurchaseOrderApplicationSuppliers().then((res) => {
      if (res.status === 200) {
        this.purchaseOrderApplicationList = res.response;
      }
    });

    getManpowerSupplierAssessmentTemplate().then((res) => {
      if (res.status === 200) {
        this.downloadUrl1 = "https://hrms.baishulai.com/" + res.response;
      }
    });
    getManpowerSupplierPunishmentAndRewardTemplate().then((res) => {
      if (res.status === 200) {
        this.downloadUrl2 = "https://hrms.baishulai.com/" + res.response;
      }
    });

    this.KeyID = this.$route.query.id;
    if (this.KeyID) {
      this.getManpowerSupplierListItem(this.KeyID);
    }

    if (this.active == 1) {
      this.getManpowerSupplierAssessments();
    } else {
      this.getManpowerSupplierPunishmentAndRewards();
    }
  },
};
</script>
<style lang="scss" scoped>
.from-box {
  width: 100%;
  min-height: calc(100% - 40px);
  background-color: #fff;
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  // padding: 0 20px;
  // padding-top: 20px;
  box-sizing: border-box;
  justify-content: space-between;
  align-content: flex-start;
  .line-box {
    width: 30%;
    margin-bottom: 24px;
    .line-title {
      font-size: 14px;
      color: #100303;
      margin-bottom: 10px;
    }
    .el-select {
      width: 100%;
    }
    .el-date-editor {
      width: 100%;
    }
    .bulue-title {
      color: #257eff;
      cursor: pointer;
    }
  }
  .line-box2 {
    width: 24%;
  }
  .line-box3 {
    width: 49%;
  }
  .line-upload {
    width: 30%;
  }
  .line {
    width: 100%;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bolder;
  }
  .upload-load {
    display: flex;
    height: 34px;
    //   margin-top: 24px;
    .left {
      width: 100%;
      border: 1px solid #d2d6de;
      border-right: none;
      .name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 34px;
        color: #555;
        font-size: 14px;
        text-indent: 2px;
        box-sizing: border-box;
        .name-title {
          width: 250px;
          overflow: hidden; /* 确保超出容器的内容被裁剪 */
          white-space: nowrap; /* 确保文本在一行内显示 */
          text-overflow: ellipsis; /* 超出部分显示省略号 */
          cursor: pointer;
          color: #315efb;
        }
        span {
          color: red;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
    .btn-up {
      padding: 0;
      margin: 0;
      border: none;
      width: 90px;
      flex-shrink: 0;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: #3c8dbc;
      border: 1px solid #367fa9;
      color: #fff;
      font-size: 14px;
      .icon {
        margin-right: 5px;
      }
    }
    .disabled {
      cursor: no-drop;
    }
    .btn-up:hover {
      border-color: #204d74;
      background-color: #367fa9;
    }
  }
  .buttons {
    width: 100%;

    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.dialog-footer {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .el-button {
    height: 34px;
    padding: 0 20px;
    margin-right: 12px;
    margin-left: 0;
  }
}
.table-box {
  margin-top: 12px;
}
.card-box {
  width: 100%;
  // min-height: calc(100% - 48px);
  // height: calc(100% - 48px);
  background: #ffffff;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  box-sizing: border-box;
  .screen-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    padding: 0 10px;
    .screen-input-box {
      margin-top: 24px;
    }
    .btn-pre {
      margin-top: 24px;
    }
    .btn-add {
      margin-top: 24px;
    }
    .el-input {
      width: 150px;
      margin-right: 24px;
    }
    .el-button {
      margin-right: 24px;
    }
    .el-select {
      width: 150px;
      margin-right: 24px;
    }
    .el-date-editor {
      margin-right: 24px;
    }
    .senior {
      position: absolute;
      width: 100%;
      top: 40px;
      left: 0;
      background: #fefefe;
      border: 1px solid #eae8e8;
      border-top-width: 1px;
      border-top-style: solid;
      border-top-color: rgb(234, 232, 232);
      box-sizing: border-box;
      z-index: 999;
      padding: 0 24px 24px 24px;
      border-top: 0;
      box-shadow: 0 7px 18px -12px #bdc0bb;
      .from-boxs {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .from-box {
          margin-top: 24px;
          .name {
            margin-bottom: 10px;
          }
          .el-select {
            width: 150px;
          }
          .el-input {
            width: 150px;
          }
        }
      }
      .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
      }
    }
  }
  .table-box {
    width: 100%;
    // height: calc(100% - 143px);
    .el-table {
      margin-top: 24px;
      // height: calc(100% - 56px);
      min-height: 500px;
      box-sizing: border-box;
    }
    .el-pagination {
      margin-top: 24px;
    }
    .table-color {
      color: #0076ff;
    }
    .buttons {
      display: flex;
    }
    .fa {
      margin-right: 10px;
      color: #3c8dbc;
      cursor: pointer;
    }
    .fa:hover {
      color: #72afd2;
    }
  }
}
.page-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .page-left {
    display: flex;
    align-items: center;
    .back {
      margin-left: 24px;
      color: #257eff;
      cursor: pointer;
    }
  }
  .page-title {
    font-size: 18px;
    color: #000000;
  }
  .breadcrumb-active {
    color: #303133;
    font-weight: bolder;
    cursor: pointer;
    .fa {
      margin-right: 5px;
    }
  }
}
.tabs-box {
  width: 100%;
  margin-top: 12px;
}
.customize-main {
  width: 100%;
  color: #000;
  padding: 24px;
  box-sizing: border-box;
  .teding {
    max-width: 200px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-red {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      color: #ff0000;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 13px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  justify-content: center;
}
.shenpi-color {
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: column;
  cursor: pointer;
  .shenpi-tips {
    color: #315efb;
    margin-left: 0;
    margin-top: 3px;
  }
}
.shenpi-color {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: row;
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
.text-box {
  display: flex;
  color: #000000;
  .text-title {
    margin-right: 24px;
  }
  .text-name {
    color: #0076ff;
    cursor: pointer;
  }
}
.upload-box2 {
  display: flex;
  height: 34px;
  margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
.dialog-footer2 {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .el-button {
    height: 34px;
    padding: 0 20px;
    margin-right: 12px;
    margin-left: 0;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.dialog-main {
  border-bottom: 1px solid #f4f4f4;
  padding: 12px 24px 0 24px;
  width: 100%;
  box-sizing: border-box;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>

<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>

<style scoped>
.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}
.tableData2 >>> .el-table__header .cell {
  font-size: 14px !important;
  font-weight: bolder !important;
}

.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
.tabs-box >>> .el-tabs__header {
  margin: 0 !important;
}
</style>
